import React from "react";
import { Link } from "gatsby"
import { Twemoji } from 'react-emoji-render'
import _ from "lodash"

const Post = ({ posts }) => {
    return (
        <ol className="post-list my-4 border-b border-gray-default" style={{ listStyle: `none` }}>
            {posts.map(post => (
                <Link to={post.fields.slug} itemProp="url">
                    <li className="post-list-item border-t border-gray-default hover:bg-gray-default" key={post.fields.slug}>
                        <article
                            className="flex py-4"
                            itemScope
                            itemType="http://schema.org/Article"
                        >
                            <div className="flex-initial mx-5 my-3">
                                <Twemoji svg className="emoji-s" text={post.frontmatter.emoji} />
                            </div>
                            <div className="flex-initial">
                                <h3 className="text-2xl md:text-lg text-blue-default font-bold">
                                    {post.frontmatter.title}
                                </h3>
                                
                                <p className="text-gray-lighter">{post.frontmatter.date}</p>
                                <div className="mt-2">
                                    {post.frontmatter.tags.map(tag =>  ( 
                                        <Link className="tag p-1 mr-2 border bg-blue-dark border-gray-default rounded" to={`/tags/${_.kebabCase(tag)}/`}>{tag}</Link>
                                    ))}
                                </div>
                                
                                   
                            </div>
                        </article>
                    </li>
                </Link>
            ))}
        </ol>
    )

}

export default Post
