import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import _ from "lodash"
import { Twemoji } from "react-emoji-render"

const TagList =() => {
    const data = useStaticQuery(graphql`
        query {
            allTagsYaml {
                nodes {
                name
                emoji
                }
            }
        }
    `)
    const Tags = data.allTagsYaml.nodes
    return(
        <ul className="tag-list flex mt-4">
            {Tags.map(tag => (
                <li className="tag-list-item text-center justify-center p-1 mr-2 w-20 flex-initial rounded hover:bg-gray-default"  key={tag.name}>
                    <Link to={`/tags/${_.kebabCase(tag.name)}/`} >
                        <div className="mx-4">
                        <Twemoji svg text={tag.emoji} className="emoji-s"/>
                        </div>
                        
                        <p className="text-blue-default">{tag.name}</p>
                    </Link>
                </li>
            ))}
        </ul>
    )
}

export default TagList