import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostList from "../components/post-list"
import TagList from "../components/tag-list"
import { graphql } from 'gatsby' 



const Tags = ({ data, location, pageContext }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  
  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="" />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={pageContext.tag} />
      <TagList />
      <h1>{pageContext.tag}</h1>
      <PostList posts={posts}/>
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { tags: { in: [$tag] } } }) {
      nodes {
        excerpt(truncate: true)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          title
          description
          emoji
          tags
        }
      }
    }
    allTagsYaml {
      nodes {
        name
        emoji
      }
    }
  }
`